import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  Fragment,
} from "react";

import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";

import { Box, Button, Grid, Typography, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Swal from "sweetalert2";
import { styled } from "@mui/system";

const StyledPaper = styled(Paper)((props) => ({
  boxShadow: "unset",
}));

const DISCARDED = "Descartado";

const reasons = [
  "",
  "Conflicto de fechas",
  "Razón no definida",
  "Compró otro seminario",
  "El temario no cumple las expectativas",
  "In company",
  "Información no válida",
  "Mudo",
  "No cuenta con presupuesto",
  "Seminario reprogramado",
  "Su jefe no lo aprobó",
  "Da largas",
  "En espera de próximas fechas",
  "Pensó que era gratis",
  "Datos incorrectos o incompletos",
  "Seminario cancelado",
  "Extranjero",
];

let discardOptions = [];
reasons.forEach((reason, index) => {
  discardOptions.push({ id: index, value: reason, label: reason });
});

const customizeMessageStyles = (message) => {
  if (message.includes("Se descarta al prospecto")) {
    const [prefix, reason] = message.split(": ");
    return (
      <Fragment>
        {prefix}: <em>{reason}</em>
      </Fragment>
    );
  }

  return message;
};

const FollowUpMessage = ({ followUp }) => {
  return (
    <Box>
      <Box borderRadius="8px">
        <Typography variant="body1" align="left" sx={{ fontSize: "14px" }}>
          <b>
            {followUp.created_by}, {followUp.created_at} -{" "}
          </b>
          {customizeMessageStyles(followUp.follow_up)}
        </Typography>
      </Box>
    </Box>
  );
};

const ScrollContainer = ({ children, scrollCta }) => {
  const outerDiv = useRef(null);
  const innerDiv = useRef(null);

  const prevInnerDivHeight = useRef(null);

  const [showMessages, setShowMessages] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const outerDivHeight = outerDiv.current.clientHeight;
    const innerDivHeight = innerDiv.current.clientHeight;
    // const outerDivScrollTop = outerDiv.current.scrollTop;

    outerDiv.current.scrollTo({
      top: innerDivHeight - outerDivHeight,
      left: 0,
      behavior: prevInnerDivHeight.current ? "smooth" : "auto",
    });
    setShowMessages(true);

    // if (
    //   !prevInnerDivHeight.current ||
    //   outerDivScrollTop === prevInnerDivHeight.current - outerDivHeight
    // ) {
    //   outerDiv.current.scrollTo({
    //     top: innerDivHeight - outerDivHeight,
    //     left: 0,
    //     behavior: prevInnerDivHeight.current ? "smooth" : "auto",
    //   });
    //   setShowMessages(true);
    // } else {
    //   setShowScrollButton(true);
    // }

    prevInnerDivHeight.current = innerDivHeight;
  }, [children]);

  const handleScrollButtonClick = useCallback(() => {
    const outerDivHeight = outerDiv.current.clientHeight;
    const innerDivHeight = innerDiv.current.clientHeight;

    outerDiv.current.scrollTo({
      top: innerDivHeight - outerDivHeight,
      left: 0,
      behavior: "smooth",
    });

    setShowScrollButton(false);
  }, []);

  return (
    <Box position="relative" maxHeight={182}>
      <Box position="relative" maxHeight={176} overflow="auto" ref={outerDiv}>
        <Box
          position="relative"
          padding="5px"
          sx={{ transition: "all 0.3s", opacity: showMessages ? 1 : 0 }}
          ref={innerDiv}
        >
          {children}
        </Box>
      </Box>
      <Button
        variant="contained"
        color="error"
        style={{
          transform: "translateX(-50%)",
          opacity: showScrollButton ? 1 : 0,
          pointerEvents: showScrollButton ? "auto" : "none",
          position: "absolute",
          bottom: "8px",
          left: "50%",
          width: "200px",
          borderRadius: "8px",
          fontSize: "14px",
          transition: "all 0.3s",
        }}
        onClick={handleScrollButtonClick}
      >
        {scrollCta}
      </Button>
    </Box>
  );
};

const ReportsContainer = ({
  registerData,
  setUpdatedRegister,
  userData,
  clicksCounter,
}) => {
  const { item: followUpElements, saveItem: addFollowUpElement } =
    useContext(AppContext);
  const [followUpMessages, setFollowUpMessages] = useState([]);

  const payload = decodeJWT(userData.access_token);

  const getFollowUpData = async () => {
    await fetch(
      `${import.meta.env.VITE_API_URL}/register/get_follow_up/${
        registerData.id
      }`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userData.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setFollowUpMessages(data);
        addFollowUpElement(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const sendFollowUp = async (isDiscard = false, discardReason = "") => {
    let bodyRequest;

    const followUpElement = document.getElementById(
      `follow-up-input-${registerData.id}`
    );
    let follow_up;

    if (isDiscard) {
      follow_up = `Se descarta al prospecto con el motivo de: ${discardReason}`;
    } else {
      follow_up = followUpElement.value;
    }

    if (!follow_up) {
      return;
    }

    const owner = payload.email;
    const register_id = registerData.id;

    bodyRequest = {
      owner,
      register_id,
      follow_up,
    };

    await fetch(`${import.meta.env.VITE_API_URL}/register/follow_up`, {
      method: "POST",
      body: JSON.stringify(bodyRequest),
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFollowUpMessages((prevData) => {
          const update = [
            ...prevData,
            ...followUpElements,
            data.updated_register,
          ].filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
          addFollowUpElement(update);
          return update;
        });
        followUpElement.value = "";
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendFollowUp();
    }
  };

  const DiscardProspectusDialog = () => {
    const [open, setOpen] = useState(false);
    const [selectHeight, setSelectHeight] = useState("0%");
    const [reason, setReason] = useState({});

    const discardClientRegister = async () => {
      let bodyRequest;

      const owner = payload.email;
      const register_id = registerData.id;
      const discarded_reason = reason.value;

      bodyRequest = {
        owner,
        register_id,
        discarded_reason,
      };

      await fetch(`${import.meta.env.VITE_API_URL}/register/discard_register`, {
        method: "POST",
        body: JSON.stringify(bodyRequest),
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userData.access_token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUpdatedRegister((prevRegisters) =>
            prevRegisters.map((register) =>
              register.id === data.updated_register.id
                ? data.updated_register
                : register
            )
          );
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            icon: "error",
          });
        });
    };

    const discardProspectus = () => {
      sendFollowUp(true, reason.value);
      discardClientRegister();
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const confirmButton = () => {
      if (Object.keys(reason).length !== 0) {
        discardProspectus();
      } else {
        Swal.fire({
          customClass: {
            container: "swal-discard-motive",
          },
          title: "Seleccione un motivo",
          icon: "warning",
        });
      }
    };

    const cancelButton = () => {
      handleClose();
    };

    return (
      <Fragment>
        <Button
          disabled={registerData.stage === DISCARDED}
          variant="contained"
          color="error"
          onClick={handleClickOpen}
          sx={{
            borderRadius: 2.5,
          }}
        >
          Descartar
        </Button>
        <Dialog
          className="prospectus-discard-dialog"
          open={open}
          aria-labelledby="dialog-title"
        >
          <DialogTitle id="dialog-title">
            Seleccione el motivo de descarte
          </DialogTitle>
          <DialogContent
            sx={{
              height: selectHeight,
            }}
          >
            <Autocomplete
              id="combo-box-reasons"
              fullWidth
              onOpen={() => setSelectHeight("480px")}
              onClose={() => setSelectHeight("0%")}
              disablePortal
              options={discardOptions}
              sx={{ marginTop: 2 }}
              onChange={(event, newValue) => setReason(newValue)}
              renderInput={(params) => (
                <TextField color="error" required {...params} label="Motivo" />
              )}
            />
          </DialogContent>

          <DialogActions>
            <Button color="error" autoFocus onClick={cancelButton}>
              cancelar
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={confirmButton}
              autoFocus
            >
              Descartar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  };

  useEffect(() => {
    if (clicksCounter % 2 === 1) {
      getFollowUpData();
    }
  }, [clicksCounter]);

  const clickInConvertionButton = (registerId) => {
    const targetButton = document.getElementById(registerId);
    if (targetButton) {
      targetButton.click();
    }
  };

  return (
    <Box
      sx={{
        width: "98%",
      }}
    >
      <Box
        sx={{
          display: followUpMessages.length === 0 ? "none" : "block",
          border: 2,
          borderColor: "#CCC",
          maxHeight: 182,
          borderRadius: "8px",
        }}
      >
        <ScrollContainer scrollCta="Últimos mensajes">
          {followUpElements.length > 0 &&
          followUpElements.at(-1)["register_id"] == registerData.id
            ? followUpElements.map((followUp, index) => (
                <FollowUpMessage followUp={followUp} key={index} />
              ))
            : followUpMessages.map((followUp, index) => (
                <FollowUpMessage followUp={followUp} key={index} />
              ))}
        </ScrollContainer>
      </Box>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <StyledPaper className="follow-up-input-container">
              <TextField
                onKeyDown={handleKeyDown}
                id={`follow-up-input-${registerData.id}`}
                className="input-follow-up"
                label="Seguimiento"
                placeholder="Escriba sus evidencias"
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                sx={{ fontSize: "14px" }}
              />
            </StyledPaper>
          </Grid>
          <Grid className="padding-top-8" item xs={4}>
            <StyledPaper
              sx={{
                mr: {
                  xs: 0,
                  xl: 1,
                },
              }}
            >
              <Stack direction="row" spacing={2}>
                <DiscardProspectusDialog />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    clickInConvertionButton(registerData.id);
                  }}
                  sx={{
                    borderRadius: 2.5,
                  }}
                >
                  Convertir
                </Button>
              </Stack>
            </StyledPaper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { ReportsContainer };
