import { useState, useEffect, Fragment } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Swal from "sweetalert2";
import { PhoneNumber } from "./PhoneNumber";
import { TelephoneInput } from "./TelephoneInput";
import { decodeJWT } from "../../Components/Utilities";
import { styled } from "@mui/material/styles";

const StyledTextButton = styled(Button)({
  textTransform: "none",
  padding: 0,
});

const ENROLLMENT = "Inscripción";
const DISCARDED = "Descartado";

const paymentMethods = [
  "01-Efectivo",
  "02-Cheque nominativo",
  "03-Transferencia electrónica de fondos",
  "04-Tarjeta de crédito",
  "99-Por definir",
];
let paymentMethodsOptions = [];
paymentMethods.forEach((method, index) => {
  paymentMethodsOptions.push({ id: index, value: method, label: method });
});

const paymentTypes = [
  "PUE - Pago en una sola exhibición",
  "PPD - Pago en parcialidades o diferido",
];
let paymentTypesOptions = [];
paymentTypes.forEach((type, index) => {
  paymentTypesOptions.push({ id: index, value: type, label: type });
});

const cfdiUsages = [
  "G02 - Devoluciones, descuentos o bonificaciones",
  "G03 - Gastos en general",
  "D04 - Donativos",
  "P01 - Por definir",
];
let cfdiUsagesOptions = [];
cfdiUsages.forEach((usage, index) => {
  cfdiUsagesOptions.push({ id: index, value: usage, label: usage });
});

// Datos de Facturación
const billingFields = [
  {
    label: "Razón social",
    required: true,
    name: "legal_name",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "RFC",
    required: true,
    name: "rfc",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 2,
  },
  {
    label: "País",
    required: true,
    name: "country",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 3,
  },
  {
    label: "Estado",
    required: true,
    name: "state",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 3,
  },
  {
    label: "Ciudad",
    required: true,
    name: "city",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Delegación/Municipio",
    required: true,
    name: "municipality",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Domicilio",
    required: true,
    name: "address",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Colonia",
    required: true,
    name: "colony",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 3,
  },
  {
    label: "Código postal",
    required: true,
    name: "zip_code",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 3,
  },
  {
    label: "Teléfono 1",
    required: true,
    name: "phone1",
    multiline: false,
    isSelect: false,
    type: "tel",
    gridSize: 3,
  },
  {
    label: "Teléfono 2",
    required: false,
    name: "phone2",
    multiline: false,
    isSelect: false,
    type: "tel",
    gridSize: 3,
  },
  {
    label: "Forma de pago",
    required: true,
    name: "payment_method",
    multiline: false,
    isSelect: true,
    type: "text",
    gridSize: 4,
    options: paymentMethodsOptions,
  },
  {
    label: "Método de pago",
    required: true,
    name: "payment_type",
    multiline: false,
    isSelect: true,
    type: "text",
    gridSize: 4,
    options: paymentTypesOptions,
  },
  {
    label: "Uso de CFDI",
    required: true,
    name: "cfdi_use",
    multiline: false,
    isSelect: true,
    type: "text",
    gridSize: 4,
    options: cfdiUsagesOptions,
  },
  {
    label: "Régimen fiscal",
    required: true,
    name: "fiscal_regime",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 6,
  },
  {
    label: "Fecha de compromiso de pago",
    required: true,
    name: "payment_commitment_date",
    multiline: false,
    isSelect: false,
    type: "date",
    gridSize: 6,
  },
];

// Contacto de seguimiento
const followUpFields = [
  {
    label: "Nombre del contacto",
    required: true,
    name: "contact_name",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Puesto",
    required: true,
    name: "contact_position",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "E-mail",
    required: true,
    name: "contact_email",
    multiline: false,
    isSelect: false,
    type: "email",
    gridSize: 4,
  },
  {
    label: "Teléfono",
    required: true,
    name: "contact_phone",
    multiline: false,
    isSelect: false,
    type: "tel",
    gridSize: 4,
  },
  {
    label: "Celular",
    required: true,
    name: "contact_cell_phone",
    multiline: false,
    isSelect: false,
    type: "tel",
    gridSize: 4,
  },
];

// Autorizacion
const authorizationFields = [
  {
    label: "Nombre completo",
    required: true,
    name: "authorization_full_name",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Puesto",
    required: true,
    name: "authorization_position",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "E-mail",
    required: true,
    name: "authorization_email",
    multiline: false,
    isSelect: false,
    type: "email",
    gridSize: 4,
  },
];

// Participants
const participantFields = [
  {
    label: "Título/Nombre",
    required: true,
    name: "full_name",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Puesto",
    required: true,
    name: "position",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "E-mail",
    required: true,
    name: "email",
    multiline: false,
    isSelect: false,
    type: "email",
    gridSize: 4,
  },
  {
    label: "Sede",
    required: true,
    name: "headquarters",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Seminario",
    required: true,
    name: "seminar",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
];

const get_company_data = async (
  companyId,
  setCompanyData,
  setParticipantValues,
  setContactValues,
  setPhoneData,
  setSameFollowUpContactData,
  userData
) => {
  await fetch(`${import.meta.env.VITE_API_URL}/company/${companyId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${userData.access_token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setCompanyData(data);
      setPhoneData({
        phone1: data.billing_details.phone1,
        phone2: data.billing_details.phone2,
        contact_phone: data.follow_up_contact.contact_phone,
        contact_cell_phone: data.follow_up_contact.contact_cell_phone,
      });

      setSameFollowUpContactData(
        data.authorization.same_follow_up_contact_data
      );

      if (data.hasOwnProperty("participants") && data.participants.length > 0) {
        setParticipantValues(data.participants);
      }

      if (
        data.hasOwnProperty("follow_up_contact") &&
        data.follow_up_contact.length > 0
      ) {
        setContactValues(data.follow_up_contact);
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Error",
        icon: "error",
      });
    });
};

const Selector = ({ isDisabled, field, savedValue }) => {
  const [selectedValue, setSelectedValue] = useState(savedValue || "");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (savedValue) {
      setSelectedValue(savedValue);
    } else setSelectedValue("");
  }, [savedValue]);

  return (
    <TextField
      disabled={isDisabled}
      size="small"
      select
      fullWidth
      label={field.label}
      value={selectedValue}
      required={field.required}
      margin="dense"
      name={field.name}
      variant="outlined"
      color="secondary"
      onChange={handleChange}
    >
      {field.options.map((option) => (
        <MenuItem key={option.id} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const DateComponent = ({ isDisabled, field, savedValue }) => {
  const [selectedDate, setSelectedDate] = useState(savedValue || "");

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (savedValue) {
      setSelectedDate(savedValue); // Asegúrate de que sea en formato 'YYYY-MM-DD'
    }
  }, [savedValue]);

  return (
    <TextField
      disabled={isDisabled}
      size="small"
      fullWidth
      required={field.required}
      margin="dense"
      label={field.label}
      name={field.name}
      type={field.type}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      color="secondary"
      value={selectedDate} // El valor del estado se refleja en el campo
      onChange={handleDateChange} // Captura el valor del input de tipo date
    />
  );
};

const InputComponent = ({
  isDisabled,
  field,
  savedValue,
  setParticipantValues,
  participantIndex,
}) => {
  const [inputValue, setInputValue] = useState(savedValue);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue(value);

    if (participantIndex === undefined) {
      return;
    }

    setParticipantValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[participantIndex] = {
        ...updatedValues[participantIndex],
        [name]: value,
      };
      return updatedValues;
    });
  };

  useEffect(() => {
    if (savedValue) {
      setInputValue(savedValue);
    }
  }, [savedValue]);

  return (
    <TextField
      disabled={isDisabled}
      size="small"
      fullWidth
      required={field.required}
      margin="dense"
      label={field.label}
      name={field.name}
      type={field.type}
      variant="outlined"
      color="secondary"
      value={inputValue}
      onChange={handleInputChange}
    />
  );
};

const PARTICIPANT = {
  full_name: "",
  position: "",
  email: "",
  headquarters: "",
  seminar: "",
};

const FOLLOW_UP_CONTACT = {
  contact_name: "",
  contact_position: "",
  contact_email: "",
  contact_phone: "",
  has_contact_phone_extension: false,
  contact_cell_phone: "",
};

const CompanyDialog = ({ registerId, companyId, company, stage, userData }) => {
  const [open, setOpen] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const [participants, setParticipants] = useState(participantFields);
  const [participantValues, setParticipantValues] = useState([]);
  const [contacts, setContacts] = useState(followUpFields);
  const [contactValues, setContactValues] = useState([]);
  const [phoneData, setPhoneData] = useState({
    phone1: "",
    phone2: "",
    contact_phone: "",
    contact_cellphone: "",
  });
  const [sameFollowUpContactData, setSameFollowUpContactData] = useState(true);
  const [editData, setEditData] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    get_company_data(
      companyId,
      setCompanyData,
      setParticipantValues,
      setContactValues,
      setPhoneData,
      setSameFollowUpContactData,
      userData
    );
  };

  const handleClose = () => {
    setOpen(false);
    setEditData(true);
  };

  const updateCompany = async (bodyRequest) => {
    await fetch(`${import.meta.env.VITE_API_URL}/company/update`, {
      method: "POST",
      body: JSON.stringify(bodyRequest),
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire({
          title: "Datos actualizados",
          icon: "success",
        });
        handleClose();
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const handleAddParticipant = () => {
    if (
      companyData.hasOwnProperty("participants") &&
      companyData.participants.length == 0
    ) {
      setParticipants((prevParticipants) => [
        ...prevParticipants,
        ...participantFields,
      ]);
    } else {
      setCompanyData((prevDataCompany) => ({
        ...prevDataCompany,
        participants: [...prevDataCompany.participants, PARTICIPANT],
      }));
    }
  };

  const handleAddContact = () => {
    if (
      companyData.hasOwnProperty("follow_up_contact") &&
      companyData.follow_up_contact.length == 0
    ) {
      setContacts((prevContacts) => [...prevContacts, ...followUpFields]);
    } else {
      setCompanyData((prevDataCompany) => ({
        ...prevDataCompany,
        follow_up_contact: [
          ...prevDataCompany.follow_up_contact,
          FOLLOW_UP_CONTACT,
        ],
      }));
    }
  };

  const handleRemoveParticipant = () => {
    if (
      companyData.hasOwnProperty("participants") &&
      companyData.participants.length == 0
    ) {
      setParticipants((prevParticipants) => prevParticipants.slice(0, -5));
    } else {
      setCompanyData((prevDataCompany) => ({
        ...prevDataCompany,
        participants: prevDataCompany.participants.slice(0, -1),
      }));
      setParticipantValues((prevValues) => prevValues.slice(0, -1));
    }
  };

  const handleRemoveContact = () => {
    if (
      companyData.hasOwnProperty("follow_up_contact") &&
      companyData.follow_up_contact.length == 0
    ) {
      setContacts((prevContacts) => prevContacts.slice(0, -5));
    } else {
      setCompanyData((prevDataCompany) => ({
        ...prevDataCompany,
        follow_up_contact: prevDataCompany.follow_up_contact.slice(0, -1),
      }));
      setContactValues((prevValues) => prevValues.slice(0, -1));
    }
  };

  const disableRemoveButton = (companyData, participants) => {
    if (
      companyData.hasOwnProperty("participants") &&
      companyData.participants.length == 0
    ) {
      return participants.length <= 5;
    } else {
      return (
        companyData.hasOwnProperty("participants") &&
        companyData.participants.length <= 1
      );
    }
  };

  const disableRemoveContactButton = (companyData, contacts) => {
    if (
      companyData.hasOwnProperty("follow_up_contact") &&
      companyData.follow_up_contact.length == 0
    ) {
      return contacts.length <= 5;
    } else {
      return (
        companyData.hasOwnProperty("follow_up_contact") &&
        companyData.follow_up_contact.length <= 1
      );
    }
  };

  const AuthorizationCheckbox = ({ isDisabled }) => {
    const handleChange = (event) => {
      setSameFollowUpContactData(event.target.checked);
    };

    return (
      <Checkbox
        disabled={isDisabled}
        checked={sameFollowUpContactData}
        onChange={handleChange}
      />
    );
  };

  const SwalError = (message) => {
    Swal.fire({
      title: "Error",
      icon: "error",
      text: message,
      didOpen: () => {
        document.querySelector(
          ".swal2-container.swal2-center.swal2-backdrop-show"
        ).style.zIndex = "1300";
      },
    });
  };

  return (
    <>
      <StyledTextButton
        onClick={handleClickOpen}
        sx={{
          lineHeight: 1,
          color: "inherit",
          fontWeight: "inherit",
        }}
      >
        {stage === ENROLLMENT || stage === DISCARDED ? (
          <span style={{ fontWeight: "bold" }}>{company}</span>
        ) : (
          company
        )}
      </StyledTextButton>
      <Dialog
        open={open}
        PaperProps={{
          component: "form",
          className: "dialog-company-form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            const payload = decodeJWT(userData.access_token);

            let companyBody = {
              billing_details: {},
              follow_up_contact: [],
              authorization: {},
              participants: [],
            };

            billingFields.forEach((field) => {
              companyBody["billing_details"][field.name] =
                field.type === "tel"
                  ? phoneData[field.name]
                  : formJson[field.name];
            });

            const PHONE_FIELDS = ["phone1", "phone2"];

            const translate = {
              phone1: "teléfono 1",
              phone2: "teléfono 2",
            };

            let errorMessages = {};

            PHONE_FIELDS.forEach((field) => {
              companyBody.billing_details[`has_${field}_extension`] =
                formJson[`has_${field}_extension`]?.trim() === "on";

              if (
                formJson[field]?.trim() &&
                formJson[`${field}_extension`]?.trim()
              ) {
                companyBody.billing_details[`${field}_extension`] =
                  formJson[`${field}_extension`];
              }

              if (
                companyBody.billing_details[`has_${field}_extension`] &&
                !companyBody.billing_details[`${field}_extension`]
              ) {
                errorMessages[
                  field
                ] = `Ha marcado agregar extensión de ${translate[field]}, pero no ha ingresado la extensión para el ${translate[field]}.`;
              }
            });

            if (Object.keys(errorMessages).length > 0) {
              SwalError(Object.values(errorMessages)[0]);
              return;
            }

            companyBody["follow_up_contact"] = contactValues;

            companyBody["authorization"]["same_follow_up_contact_data"] =
              sameFollowUpContactData;

            if (sameFollowUpContactData) {
              const equivalentNames = {
                authorization_full_name: "contact_name",
                authorization_position: "contact_position",
                authorization_email: "contact_email",
              };

              let followUpContact = { ...companyBody.follow_up_contact[0] };
              authorizationFields.forEach((field) => {
                companyBody["authorization"][field.name] =
                  followUpContact[equivalentNames[field.name]];
              });
            } else {
              authorizationFields.forEach((field) => {
                companyBody["authorization"][field.name] =
                  field.type === "tel"
                    ? phoneData[field.name]
                    : formJson[field.name];
              });
            }

            companyBody["company_id"] = companyId;
            companyBody["register_id"] = registerId;
            companyBody["owner"] = payload.email;
            companyBody["is_purchase"] = false;
            companyBody["participants"] = participantValues;

            updateCompany(companyBody);

            handleClose();
          },
        }}
      >
        <DialogTitle align="center">
          <b>Datos de la empresa {company}</b>
        </DialogTitle>
        <DialogContent className="DialogContent-container">
          <DialogContentText mb={1}>
            <b>DATOS DE FACTURACIÓN</b>
          </DialogContentText>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {billingFields.map((field, index) =>
                field.isSelect ? (
                  <Grid key={index} item xs={field.gridSize}>
                    <Selector
                      isDisabled={editData}
                      field={field}
                      savedValue={
                        companyData.hasOwnProperty("billing_details") &&
                        companyData.billing_details[field.name]
                          ? companyData.billing_details[field.name]
                          : false
                      }
                    />
                  </Grid>
                ) : field.type === "tel" ? (
                  <Grid key={index} item xs={field.gridSize}>
                    <TelephoneInput
                      isDisabled={editData}
                      initialState={phoneData}
                      setValueWrapper={setPhoneData}
                      fieldLabel={field.label}
                      fieldName={field.name}
                      hasTelephoneExtension={
                        companyData.billing_details?.[
                          `has_${field.name}_extension`
                        ] ?? false
                      }
                      savedTelephone={
                        companyData.billing_details?.[field.name] ?? ""
                      }
                      savedTelephoneExtension={
                        companyData.billing_details?.[
                          `${field.name}_extension`
                        ] ?? ""
                      }
                      required={field.required}
                      size="small"
                      fontSizeCheckbox={"14px"}
                      checkboxName={`has_${field.name}_extension`}
                      extensionName={`${field.name}_extension`}
                    />
                  </Grid>
                ) : field.type === "date" ? (
                  <Grid key={index} item xs={field.gridSize}>
                    <DateComponent
                      isDisabled={editData}
                      field={field}
                      savedValue={
                        companyData.hasOwnProperty("billing_details") &&
                        companyData.billing_details[field.name]
                          ? companyData.billing_details[field.name]
                          : false
                      }
                    />
                  </Grid>
                ) : (
                  <Grid key={index} item xs={field.gridSize}>
                    <InputComponent
                      isDisabled={editData}
                      field={field}
                      savedValue={
                        companyData.hasOwnProperty("billing_details") &&
                        companyData.billing_details[field.name]
                          ? companyData.billing_details[field.name]
                          : ""
                      }
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>

          {/* INICIO DE NUEVA SECCION */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
              mt: 2,
            }}
          >
            <DialogContentText>
              <b>CONTACTO DE SEGUIMIENTO</b>
            </DialogContentText>
            {editData ? (
              <IconButton disabled aria-label="add">
                <AddIcon />
              </IconButton>
            ) : (
              <IconButton aria-label="add" onClick={handleAddContact}>
                <AddIcon />
              </IconButton>
            )}
            {editData ? (
              <IconButton disabled aria-label="remove">
                <RemoveIcon />
              </IconButton>
            ) : (
              <IconButton
                disabled={disableRemoveContactButton(companyData, contacts)}
                aria-label="remove"
                onClick={handleRemoveContact}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={20}>
              {companyData.hasOwnProperty("follow_up_contact") &&
              companyData.follow_up_contact.length > 0
                ? companyData.follow_up_contact.map((contact, contactIndex) => (
                    <Fragment key={contactIndex}>
                      {contacts.map((field, index) =>
                        field.type === "tel" ? (
                          <Grid key={index} item xs={field.gridSize}>
                            {field.name === "contact_cell_phone" ? (
                              <TelephoneInput
                                isDisabled={editData}
                                isCellPhone={
                                  field.name === "contact_cell_phone"
                                }
                                savedTelephone={contact?.[field.name] ?? ""}
                                fieldLabel={field.label}
                                fieldName={field.name}
                                setElementValues={setContactValues}
                                elementIndex={contactIndex}
                                size="small"
                                required={field.required}
                              />
                            ) : (
                              <TelephoneInput
                                isDisabled={editData}
                                fieldLabel={field.label}
                                fieldName={field.name}
                                hasTelephoneExtension={
                                  contact?.[`has_${field.name}_extension`] ??
                                  false
                                }
                                savedTelephone={contact?.[field.name] ?? ""}
                                setElementValues={setContactValues}
                                elementIndex={contactIndex}
                                savedTelephoneExtension={
                                  contact?.[`${field.name}_extension`] ?? ""
                                }
                                required={field.required}
                                size="small"
                                fontSizeCheckbox={"14px"}
                                checkboxName={`has_${field.name}_extension`}
                                extensionName={`${field.name}_extension`}
                              />
                            )}
                          </Grid>
                        ) : (
                          <Grid key={index} item xs={field.gridSize}>
                            <InputComponent
                              isDisabled={editData}
                              field={field}
                              savedValue={contact[field.name]}
                              setParticipantValues={setContactValues}
                              participantIndex={contactIndex}
                            />
                          </Grid>
                        )
                      )}
                    </Fragment>
                  ))
                : contacts.map((field, index) =>
                    field.type === "tel" ? (
                      <Grid key={index} item xs={field.gridSize}>
                        {field.name === "contact_cell_phone" ? (
                          <TelephoneInput
                            isDisabled={editData}
                            isCellPhone={field.name === "contact_cell_phone"}
                            fieldLabel={field.label}
                            fieldName={field.name}
                            setElementValues={setContactValues}
                            elementIndex={0}
                            required={field.required}
                            size="small"
                          />
                        ) : (
                          <TelephoneInput
                            isDisabled={editData}
                            fieldLabel={field.label}
                            fieldName={field.name}
                            setElementValues={setContactValues}
                            elementIndex={0}
                            required={field.required}
                            size="small"
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid key={index} item xs={field.gridSize}>
                        <InputComponent
                          isDisabled={editData}
                          field={field}
                          savedValue={""}
                          setParticipantValues={setContactValues}
                          participantIndex={Math.floor(index / 5)}
                        />
                      </Grid>
                    )
                  )}
            </Grid>
          </Box>

          {/* FIN DE NUEVA SECCION */}

          <DialogContentText mt={2} mb={1}>
            <b>AUTORIZACIÓN</b>
          </DialogContentText>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<AuthorizationCheckbox isDisabled={editData} />}
                  label="Utilizar datos del contacto de seguimiento"
                  sx={{
                    color: editData ? "#bdbdbd" : "inherit",
                    cursor: editData ? "default" : "pointer",
                  }}
                />
              </Grid>
              {!sameFollowUpContactData &&
                authorizationFields.map((field, index) =>
                  field.isSelect ? (
                    <Grid key={index} item xs={field.gridSize}>
                      <Selector
                        isDisabled={editData}
                        field={field}
                        savedValue={
                          companyData.hasOwnProperty("authorization") &&
                          companyData.authorization[field.name]
                            ? companyData.authorization[field.name]
                            : false
                        }
                      />
                    </Grid>
                  ) : field.type === "tel" ? (
                    <Grid key={index} item xs={field.gridSize}>
                      <PhoneNumber
                        isDisabled={editData}
                        initialState={phoneData}
                        savedValue={
                          companyData.hasOwnProperty("authorization") &&
                          companyData.authorization[field.name]
                            ? companyData.authorization[field.name]
                            : false
                        }
                        setValueWrapper={setPhoneData}
                        fieldLabel={field.label}
                        fieldName={field.name}
                        size="small"
                        required={field.required}
                      />
                    </Grid>
                  ) : (
                    <Grid key={index} item xs={field.gridSize}>
                      <InputComponent
                        isDisabled={editData}
                        field={field}
                        savedValue={
                          companyData.hasOwnProperty("authorization") &&
                          companyData.authorization[field.name]
                            ? companyData.authorization[field.name]
                            : ""
                        }
                      />
                    </Grid>
                  )
                )}
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
              mt: 2,
            }}
          >
            <DialogContentText>
              <b>PARTICIPANTES</b>
            </DialogContentText>
            <IconButton
              disabled={editData}
              aria-label="add"
              onClick={handleAddParticipant}
            >
              <AddIcon />
            </IconButton>
            {editData ? (
              <IconButton disabled aria-label="remove">
                <RemoveIcon />
              </IconButton>
            ) : (
              <IconButton
                disabled={disableRemoveButton(companyData, participants)}
                aria-label="remove"
                onClick={handleRemoveParticipant}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={20}>
              {companyData.hasOwnProperty("participants") &&
              companyData.participants.length > 0
                ? companyData.participants.map(
                    (participant, participantIndex) => (
                      <Fragment key={participantIndex}>
                        {participants.map((field, index) =>
                          field.isSelect ? (
                            <Grid key={index} item xs={field.gridSize}>
                              <Selector
                                isDisabled={editData}
                                field={field}
                                savedValue={participant[field.name]}
                              />
                            </Grid>
                          ) : field.type === "tel" ? (
                            <Grid key={index} item xs={field.gridSize}>
                              <PhoneNumber
                                isDisabled={editData}
                                initialState={phoneData}
                                savedValue={participant[field.name]}
                                setValueWrapper={setPhoneData}
                                fieldLabel={field.label}
                                fieldName={field.name}
                                size="small"
                                required={field.required}
                              />
                            </Grid>
                          ) : (
                            <Grid key={index} item xs={field.gridSize}>
                              <InputComponent
                                isDisabled={editData}
                                field={field}
                                savedValue={participant[field.name]}
                                setParticipantValues={setParticipantValues}
                                participantIndex={participantIndex}
                              />
                            </Grid>
                          )
                        )}
                      </Fragment>
                    )
                  )
                : participants.map((field, index) =>
                    field.isSelect ? (
                      <Grid key={index} item xs={field.gridSize}>
                        <TextField
                          disabled={editData}
                          size="small"
                          select
                          fullWidth
                          label={field.label}
                          defaultValue=""
                          required={field.required}
                          margin="dense"
                          name={field.name}
                          variant="outlined"
                          color="secondary"
                        >
                          {field.options.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    ) : field.type === "tel" ? (
                      <Grid key={index} item xs={field.gridSize}>
                        <PhoneNumber
                          isDisabled={editData}
                          fieldLabel={field.label}
                          fieldName={field.name}
                          size="small"
                        />
                      </Grid>
                    ) : (
                      <Grid key={index} item xs={field.gridSize}>
                        <InputComponent
                          isDisabled={editData}
                          field={field}
                          savedValue={""}
                          setParticipantValues={setParticipantValues}
                          participantIndex={Math.floor(index / 5)}
                        />
                      </Grid>
                    )
                  )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancelar
          </Button>
          {editData && (
            <Button
              color="warning"
              variant="contained"
              onClick={() => {
                setEditData(!editData);
              }}
            >
              Editar
            </Button>
          )}
          {!editData && (
            <Button type="submit" disabled={editData} variant="contained">
              Actualizar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export { CompanyDialog };
