import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { Fragment } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";

import { ReportsContainer } from "./ReportsContainer";
import { CompanyDialog } from "./CompanyDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, custom_color }) => ({
  // backgroundColor: index % 2 === 0 ? "#e7e9eb" : "#fff",
  backgroundColor: custom_color,
}));

const StyledPaper = styled(Paper)((props) => ({
  boxShadow: "unset",
}));

const StyledTextButton = styled(Button)({
  textTransform: "none",
  padding: 0,
});

const NEW_APPLICATION = "Nueva solicitud";
const PDF_SENT = "PDF enviado";
const PDF_RECEIPT_CONFIRMED = "Recepción del PDF confirmada";
const SUCCESSFUL_CALL = "Llamada exitosa";
const ON_AUTHORIZATION = "En autorización";
const ENROLLMENT = "Inscripción";
const DISCARDED = "Descartado";

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow index={1}>
        {headCells.map((headCell) => (
          <StyledTableCell
            className="table-head-custom-row"
            key={headCell.id}
            component="th"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {headCell.name}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
};

const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const UserDataTable = ({
  registerData,
  setUpdatedRegister,
  userData,
  clicksCounter,
}) => {
  let clientInfo = { ...registerData };
  delete clientInfo.id;
  delete clientInfo.company_id;

  if (clientInfo.has_telephone_extension || false) {
    clientInfo.telephone = `${clientInfo.telephone} Ext: ${clientInfo.telephone_extension}`;
  }

  delete clientInfo.has_telephone_extension;
  delete clientInfo.telephone_extension;

  const entries = Object.entries(clientInfo);
  const chunks = chunkArray(entries, 5);
  const dividedObjects = chunks.map((chunk) => Object.fromEntries(chunk));

  const LABELS = {
    client_id: "ID cliente: ",
    related_courses: "Cursos relacionados: ",
    title: "Título: ",
    full_name: "Nombre completo: ",
    main_email: "Email principal: ",
    additional_emails: "Emails adicionales: ",
    status: "Estatus: ",
    stage: "Etapa: ",
    interes: "Interés: ",
    pax: "Pax: ",
    origin: "Origen: ",
    origin_media: "Medio de origen: ",
    company: "Empresa: ",
    position: "Puesto: ",
    cell_phone: "Número celular: ",
    telephone: "Número telefónico: ",
    created_at: "Creado en: ",
    created_by: "Creado por: ",
    updated_at: "Actualizado en: ",
    updated_by: "Actualizado por: ",
  };

  return (
    <div className="user-data-table-container" sx={{ margin: 0 }}>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <Grid container spacing={2}>
          <Grid className="user-data-details-container" item xs={12}>
            <StyledPaper
              sx={{
                borderStyle: "solid",
                borderRadius: 2,
                borderWidth: 2,
                borderColor: "#9c27b0",
              }}
            >
              <Box sx={{ flexGrow: 4 }}>
                <Grid container spacing={0} columns={8}>
                  <div className="paper-cards-container">
                    {dividedObjects.map((groupElements, index) => (
                      <Grid item xs={1} key={index}>
                        <StyledPaper
                          sx={{
                            margin: "0",
                          }}
                        >
                          <Box
                            className="user-data-container"
                            sx={{ width: "100%" }}
                          >
                            <List
                              sx={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                margin: "0",
                              }}
                            >
                              {Object.keys(groupElements).map((key) =>
                                key === "id" ? null : (
                                  <ListItem
                                    key={key}
                                    sx={{ padding: "0 3px 0" }}
                                  >
                                    <ListItemText
                                      className="user-data-text"
                                      sx={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        fontSize: "14px",
                                      }}
                                    >
                                      <b> {LABELS[key]} </b>
                                      {key === "related_courses"
                                        ? groupElements[key].join(", ")
                                        : groupElements[key]}
                                    </ListItemText>
                                  </ListItem>
                                )
                              )}
                            </List>
                          </Box>
                        </StyledPaper>
                      </Grid>
                    ))}
                  </div>

                  <Grid item xs={4}>
                    <StyledPaper sx={{ ml: 5 }}>
                      <Typography
                        variant="h6"
                        color={"secondary"}
                        align="left"
                        sx={{
                          margin: 0,
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        Reportes de seguimiento
                      </Typography>
                      <ReportsContainer
                        registerData={registerData}
                        setUpdatedRegister={setUpdatedRegister}
                        userData={userData}
                        clicksCounter={clicksCounter}
                      />
                    </StyledPaper>
                  </Grid>
                </Grid>
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const Row = (props) => {
  const {
    row,
    index,
    ModifyButton,
    userData,
    ownerEmail,
    ConvertionButton,
    setUpdatedRegister,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const [clicksCounter, setClicksCounter] = useState(0);

  const handleUserDataDetails = () => {
    setOpen((prevState) => !prevState);
    setClicksCounter((prevState) => prevState + 1);
  };

  const chooseColor = (stage) => {
    switch (stage) {
      case NEW_APPLICATION:
        return "#9bc1e6";
      case PDF_SENT:
        return "#f8fc9b";
      case PDF_RECEIPT_CONFIRMED:
        return "#c6e0b3";
      case SUCCESSFUL_CALL:
        return "#e9c5f3";
      case ON_AUTHORIZATION:
        return "#ffc000";
      case ENROLLMENT:
        return "#ffe700";
      case DISCARDED:
        return "#afa9a9";
      default:
        return "#fff";
    }
  };

  return (
    <>
      <StyledTableRow key={row.id} custom_color={chooseColor(row.stage)}>
        <TableCell
          className="table-data-custom-row"
          align="center"
          sx={{ minWidth: "110px" }}
        >
          {row.status}
        </TableCell>
        <TableCell className="table-data-custom-row" align="center">
          {row.stage}
        </TableCell>
        <TableCell className="table-data-custom-row" align="center">
          {row.stage === ENROLLMENT ? (
            <span style={{ color: "red", fontWeight: "bold" }}>{row.pax}</span>
          ) : (
            row.pax
          )}
        </TableCell>
        <TableCell className="table-data-custom-row" align="center">
          <CompanyDialog
            registerId={row.id}
            companyId={row.company_id}
            company={row.company}
            stage={row.stage}
            userData={userData}
          />
        </TableCell>
        <TableCell className="table-data-custom-row" align="center">
          <StyledTextButton
            variant="text"
            onClick={handleUserDataDetails}
            sx={{
              lineHeight: 1,
              color: isOpen ? "#9c27b0" : "inherit",
              fontWeight: isOpen ? "bold" : "inherit",
            }}
          >
            {`${row.title} ${row.full_name}`}
          </StyledTextButton>
        </TableCell>
        <TableCell className="table-data-custom-row" align="center">
          {row.position}
        </TableCell>
        <TableCell className="table-data-custom-row" align="center">
          {row.main_email}
        </TableCell>
        <TableCell className="table-data-custom-row" align="center">
          {row.additional_emails}
        </TableCell>
        <TableCell className="table-data-custom-row" align="center">
          <Stack direction="row" spacing={1}>
            <ConvertionButton
              registerId={row.id}
              companyId={row.company_id}
              company={row.company}
              ownerEmail={ownerEmail}
              clientName={`${row.title} ${row.full_name}`}
              userData={userData}
              setUpdatedRegister={setUpdatedRegister}
            />
            <ModifyButton
              userData={userData}
              ownerEmail={ownerEmail}
              registerData={row}
              setUpdatedRegister={setUpdatedRegister}
            />
          </Stack>
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell
          className="user-data"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
        >
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <UserDataTable
              registerData={row}
              setUpdatedRegister={setUpdatedRegister}
              userData={userData}
              clicksCounter={clicksCounter}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const EnhancedTable = ({
  registers,
  headCells,
  ModifyButton,
  userData,
  ownerEmail,
  ConvertionButton,
  setUpdatedRegister,
}) => {
  const initialRowsPerPage = 33;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () => registers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, registers]
  );

  return (
    <Box>
      <StyledPaper sx={{ mb: 2 }}>
        <TablePagination
          className="table-pagination"
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count !== -1 ? count : to}`
          }
          rowsPerPageOptions={[initialRowsPerPage, 50, 100]}
          component="div"
          count={registers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <TableContainer sx={{ maxHeight: "88vh" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750, tableLayout: "auto" }}
            aria-labelledby="tableTitle"
            size="large"
          >
            <EnhancedTableHead headCells={headCells} />
            <TableBody>
              {visibleRows.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  ModifyButton={ModifyButton}
                  userData={userData}
                  ownerEmail={ownerEmail}
                  ConvertionButton={ConvertionButton}
                  setUpdatedRegister={setUpdatedRegister}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>
    </Box>
  );
};

export { EnhancedTable };
