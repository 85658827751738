import { useState, useMemo } from "react";
import Swal from "sweetalert2";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import { DeleteDialog } from "./DeleteForm";
import { ButtonWithForm } from "./ButtonWithForm";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ButtonGroup from "@mui/material/ButtonGroup";

import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledPaper = styled(Paper)((props) => ({
  boxShadow: "unset",
}));

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const FORM_DATA = [
  {
    name: "course_name",
    label: "Nombre del curso",
    placeholder: "Nombre del curso",
    isDate: false,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "course_date",
    label: "Fecha del curso",
    placeholder: "Día / Mes / Año",
    isDate: true,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "course_duration",
    label: "Duración del curso",
    placeholder: "Seleccione una duración",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
  {
    name: "early_payment_date",
    label: "Fecha de pronto pago",
    placeholder: "Día / Mes / Año",
    isDate: true,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "modality",
    label: "Modalidad",
    placeholder: "Seleccione una modalidad",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
  {
    name: "responsible_seller",
    label: "Vendedor responsable del curso",
    placeholder: "Seleccione un vendedor",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
];

const headerNames = [
  "Curso",
  "Duración",
  "Modalidad",
  "Vendedor Responsable",
  "Creador",
  "Actualizador",
  "Fecha Creación",
  "Fecha Actualización",
  "Acciones",
];

let headCells = [];
headerNames.forEach((name, index) => {
  headCells.push({ id: index, name: name });
});

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow index={1}>
        {headCells.map((headCell) => (
          <StyledTableCell
            className="table-head-custom-row"
            key={headCell.id}
            component="th"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {headCell.name}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
};

const EnhancedTable = ({
  accessToken,
  registers,
  setRegisters,
  sellers,
  setSellers,
  userEmail,
}) => {
  const initialRowsPerPage = 33;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () => registers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, registers]
  );

  const deleteCourse = async (courseId) => {
    await fetch(
      `${import.meta.env.VITE_API_URL}/course/delete_course/${courseId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setRegisters((prevCourses) =>
          prevCourses.filter((course) => course.id !== courseId)
        );
        Swal.fire({
          title: "Curso eliminado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const updateCourse = async (bodyRequest) => {
    await fetch(`${import.meta.env.VITE_API_URL}/course/update_course`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(bodyRequest),
    })
      .then((response) => response.json())
      .then((data) => {
        setRegisters((prevCourses) =>
          prevCourses.map((course) =>
            course.id === data.updated_course.id ? data.updated_course : course
          )
        );

        Swal.fire({
          title: "Curso actualizado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const getCourseData = (courseId) => {
    let response = registers.find((user) => user.id === courseId);
    return response;
  };

  const translateText = (text) => {
    switch (text) {
      case "course_name":
        return "Nombre del curso";
      case "updated_at":
        return "Fecha de actualización";
      case "updated_by":
        return "Actualizado por";
      default:
        return text;
    }
  };

  return (
    <Box>
      <StyledPaper sx={{ mb: 2 }}>
        <TablePagination
          className="table-pagination"
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count !== -1 ? count : to}`
          }
          rowsPerPageOptions={[initialRowsPerPage, 50, 100]}
          component="div"
          count={registers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <TableContainer sx={{ maxHeight: "88vh" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750, tableLayout: "auto" }}
            aria-labelledby="tableTitle"
            size="large"
          >
            <EnhancedTableHead headCells={headCells} />
            <TableBody>
              {visibleRows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    {row.course_name}
                  </TableCell>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    {row.course_duration}
                  </TableCell>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    {row.modality}
                  </TableCell>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    {row.responsible_seller}
                  </TableCell>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    {row.created_by}
                  </TableCell>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    {row.updated_by}
                  </TableCell>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    {row.created_at}
                  </TableCell>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    {row.updated_at}
                  </TableCell>
                  <TableCell
                    className="table-data-custom-row-users"
                    align="center"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <ButtonGroup
                        size="small"
                        variant="outlined"
                        aria-label="Basic button group"
                      >
                        <ButtonWithForm
                          mainButton={<EditNoteOutlinedIcon />}
                          formFields={FORM_DATA}
                          courseData={getCourseData(row.id)}
                          userEmail={userEmail}
                          translation={translateText}
                          submitFunction={updateCourse}
                          sellerUsers={sellers}
                          formTitle={"Editar Curso"}
                          formContent={
                            "Puedes modificar los datos del curso seleccionado"
                          }
                          confirmButton={"Guardar Cambios"}
                        />
                        <DeleteDialog
                          mainButton={<DeleteIcon />}
                          formTitle={"Eliminar Curso"}
                          formContent={
                            <span>
                              ¿Estás seguro de que deseas eliminar el curso{" "}
                              <b>{row.course_name}</b>?
                            </span>
                          }
                          confirmButton={"Sí, eliminar"}
                          courseId={row.id}
                          deleteFunction={deleteCourse}
                        />
                      </ButtonGroup>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>
    </Box>
  );
};

export { EnhancedTable };
