import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../AuthContext";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { useState } from "react";
import CrmLogo from "../../assets/crm_logo.png";
import LoginImage from "../../assets/login.png";
import Swal from "sweetalert2";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";

import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")((props) => ({
  height: props.height,
}));

const Login = () => {
  const { saveDataUser } = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const fields = [
    {
      id: "email",
      label: "Email",
      type: "email",
      required: true,
      name: "email",
      placeholder: "Escriba su email",
      icon: <EmailOutlinedIcon />,
      endIcon: false,
      errorMessage: emailError,
      hasError: emailError !== "",
    },
    {
      id: "password",
      label: "Contraseña",
      type: showPassword ? "text" : "password",
      required: true,
      name: "password",
      placeholder: "Escriba su contraseña",
      icon: <HttpsOutlinedIcon />,
      endIcon: true,
      errorMessage: passwordError,
      hasError: passwordError !== "",
    },
  ];

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const sendCredentials = async (email, password) => {
    await fetch(`${import.meta.env.VITE_API_URL}/login/`, {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hasOwnProperty("access_token")) {
          saveDataUser(data);
          navigate("/home");
        }
        if (data.hasOwnProperty("error")) {
          Swal.fire({
            title: "Acceso denegado",
            text: data.error,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Ocurrió un error",
          text: err.message,
          icon: "error",
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get("email");
    let password = data.get("password");

    setEmailError("");
    setPasswordError("");

    if (email === "") {
      setEmailError("Ingrese un email");
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Email inválido");
      return;
    }

    if (password === "") {
      setPasswordError("Ingrese una contraseña");
      return;
    }

    sendCredentials(email, password);
  };

  return (
    <div className="login-container">
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <Card
                sx={{
                  height: 580,
                  width: 414,
                  borderRadius: 8,
                  marginTop: {
                    xs: "32px",
                    xl: "120px",
                  },
                }}
              >
                <div>
                  <CardMedia
                    component="img"
                    image={CrmLogo}
                    alt="crm logo"
                    sx={{
                      borderRadius: "50%",
                      height: "200px",
                      width: "200px",
                      margin: "35px auto 20px",
                    }}
                  />
                  <CardContent>
                    <Stack
                      component="form"
                      spacing={3}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      {fields.map((field) => (
                        <TextField
                          key={field.id}
                          id={field.id}
                          fullWidth
                          error={field.hasError}
                          helperText={field.errorMessage}
                          label={field.label}
                          type={field.type}
                          required={field.required}
                          name={field.name}
                          placeholder={field.placeholder}
                          color="secondary"
                          autoComplete="off"
                          variant="outlined"
                          className="login-input"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {field.icon}
                              </InputAdornment>
                            ),
                            endAdornment: field.endIcon ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                            style: {
                              borderRadius: "28px",
                              backgroundColor: "#cccccc40",
                            },
                          }}
                        />
                      ))}
                      {emailError !== "" || passwordError !== "" ? (
                        <StyledDiv height="7px" />
                      ) : (
                        <StyledDiv height="30px" />
                      )}
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{
                          borderRadius: "28px",
                          height: 56,
                          fontWeight: "bold",
                        }}
                      >
                        Inicia sesión
                      </Button>
                    </Stack>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            <Grid item>
              <Card
                sx={{
                  height: 650,
                  width: 850,
                  borderRadius: 8,
                  backgroundColor: "#fff0",
                  boxShadow: "unset",
                  backgroundImage: `url(${LoginImage})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  marginTop: {
                    xs: 0,
                    xl: "80px",
                  },
                }}
              ></Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export { Login };
