import { Fragment, useState, useEffect, useContext } from "react";

import Swal from "sweetalert2";

import { Navbar } from "../../Components/Navbar";
import { StandardForm } from "./StandardForm";
import { Carousel } from "./Carousel";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";
import { Container, Paper, TextField, Button, Tooltip } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { EnhancedTable } from "./EnhancedTable";
import PostAddIcon from "@mui/icons-material/PostAdd";

import { Grid, Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useWindowWidth } from "../../Tools/Shared";

import Stack from "@mui/material/Stack";

import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#9c27b0",
  fontWeight: "bold",
  marginBottom: theme.spacing(4),
  textAlign: "center",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
}));

const StyledPaper = styled(Paper)((props) => ({
  boxShadow: "unset",
}));

const buildSelectOptions = (fieldName, optionsData) => {
  let options = [];

  switch (fieldName) {
    case "course_duration":
      options = optionsData["duration_options"];
      break;
    case "modality":
      options = optionsData["modality_options"];
      break;
    case "responsible_seller":
      options = optionsData["seller_options"];
      break;
  }

  return options.map((option) => (
    <MenuItem key={option.id} value={option.value}>
      {option.label}
    </MenuItem>
  ));
};

const durations = [
  "",
  "1 día",
  "2 días",
  "3 días",
  "4 días",
  "5 días",
  "6 días",
  "7 días",
];

let durationOptions = durations.map((duration, index) => ({
  id: index,
  value: duration,
  label: duration,
}));

const modalities = [
  "",
  "Online",
  "Presencial",
  "Híbrido",
  "In Company",
  "Póliza",
];

let modalityOptions = modalities.map((modality, index) => ({
  id: index,
  value: modality,
  label: modality,
}));

const FORM_DATA = [
  {
    name: "course_name",
    label: "Nombre del curso",
    placeholder: "Nombre del curso",
    isDate: false,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "course_date",
    label: "Fecha del curso",
    placeholder: "Día / Mes / Año",
    isDate: true,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "course_duration",
    label: "Duración del curso",
    placeholder: "Seleccione una duración",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
  {
    name: "early_payment_date",
    label: "Fecha de pronto pago",
    placeholder: "Día / Mes / Año",
    isDate: true,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "modality",
    label: "Modalidad",
    placeholder: "Seleccione una modalidad",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
  {
    name: "responsible_seller",
    label: "Vendedor responsable del curso",
    placeholder: "Seleccione un vendedor",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
];

const AddNewCourse = ({ courses, setCourses, sellerUsers }) => {
  const { userData } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [courseDate, setCourseDate] = useState(null);
  const [earlyPaymentDate, setEarlyPaymentDate] = useState(null);
  const [courseDuration, setCourseDuration] = useState("");
  const [courseModality, setCourseModality] = useState("");
  const [responsibleSeller, setResponsibleSeller] = useState("");

  const payload = decodeJWT(userData.access_token);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const chooseDefaultValue = (fieldName) => {
    switch (fieldName) {
      case "course_duration":
        return courseDuration;
      case "modality":
        return courseModality;
      case "responsible_seller":
        return responsibleSeller;
    }
  };

  const setNewValue = (fieldName, newValue) => {
    switch (fieldName) {
      case "course_duration":
        setCourseDuration(newValue);
        break;
      case "modality":
        setCourseModality(newValue);
        break;
      case "responsible_seller":
        setResponsibleSeller(newValue);
        break;
    }
  };

  const CourseDatePicker = () => {
    return (
      <DesktopDatePicker
        disablePast
        value={courseDate}
        onChange={(newValue) => setCourseDate(newValue)}
        format="DD/MM/YYYY"
        label={"Día / Mes / Año"}
        views={["year", "month", "day"]}
        slots={{
          textField: (props) => <TextField color="secondary" {...props} />,
        }}
      />
    );
  };

  const EarlyDatePicker = () => {
    return (
      <DesktopDatePicker
        disablePast
        value={earlyPaymentDate}
        onChange={(newValue) => setEarlyPaymentDate(newValue)}
        format="DD/MM/YYYY"
        label={"Día / Mes / Año"}
        views={["year", "month", "day"]}
        slots={{
          textField: (props) => <TextField color="secondary" {...props} />,
        }}
      />
    );
  };

  const courseFields = [
    "course_duration",
    "course_name",
    "modality",
    "responsible_seller",
  ];

  const addCourse = async (event) => {
    event.preventDefault();

    let bodyRequest = {};

    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    bodyRequest["creator_user"] = payload.email;
    bodyRequest["course_date"] = courseDate.toISOString();
    bodyRequest["early_payment_date"] = earlyPaymentDate.toISOString();
    courseFields.forEach((field) => {
      bodyRequest[field] = formJson[field];
    });

    await fetch(`${import.meta.env.VITE_API_URL}/course/new_course`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
      body: JSON.stringify(bodyRequest),
    })
      .then((response) => response.json())
      .then((data) => {
        setCourseDate(null);
        setEarlyPaymentDate(null);
        setCourseDuration("");
        setCourseModality("");
        setResponsibleSeller("");

        setCourses([...courses, data.created_course]);
        Swal.fire({
          title: "Curso creado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const screenWidth = useWindowWidth();

  return (
    <Fragment>
      {screenWidth >= 1600 ? (
        <Button
          onClick={handleClickOpen}
          variant="contained"
          className="add-new-register-button-desktop"
          sx={{
            borderRadius: 5,
          }}
        >
          Nuevo Curso
        </Button>
      ) : (
        <Tooltip title="Nuevo curso">
          <Button
            onClick={handleClickOpen}
            variant="contained"
            className="add-new-register-button-mobile"
          >
            <PostAddIcon />
          </Button>
        </Tooltip>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            addCourse(event);
            handleClose();
          },
        }}
      >
        <DialogTitle>Agregar Nuevo Curso</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingrese la información del nuevo curso
          </DialogContentText>
          <Stack mb={2}>
            {FORM_DATA.map((field, index) => (
              <Fragment key={index}>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontWeight={600}
                  component="label"
                  htmlFor={field.name}
                  mb="5px"
                  mt={index === 0 ? "0" : "20px"}
                  sx={{
                    fontFamily: "Roboto Slab, serif",
                  }}
                >
                  {field.label} {field.required ? "*" : ""}
                </Typography>
                {field.isDate && field.name === "course_date" ? (
                  <CourseDatePicker />
                ) : field.isDate && field.name === "early_payment_date" ? (
                  <EarlyDatePicker />
                ) : field.isSelect ? (
                  <TextField
                    select
                    id={field.name}
                    name={field.name}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    value={chooseDefaultValue(field.name)}
                    onChange={(event) =>
                      setNewValue(field.name, event.target.value)
                    }
                    label={field.placeholder}
                    required={field.required}
                    margin="dense"
                  >
                    {buildSelectOptions(field.name, {
                      duration_options: durationOptions,
                      modality_options: modalityOptions,
                      seller_options: sellerUsers,
                    })}
                  </TextField>
                ) : (
                  <TextField
                    id={field.name}
                    name={field.name}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    placeholder={field.placeholder}
                    multiline={field.multiline}
                    rows={field.multiline ? 3 : 1}
                    required={field.required}
                  />
                )}
              </Fragment>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="secondary" variant="contained" type="submit">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const AddCourse = () => {
  const { userData } = useContext(AppContext);
  const [courses, setCourses] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [disableRestart, setDisableRestart] = useState(true);

  const payload = decodeJWT(userData.access_token);

  const get_all_courses = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/course/get_courses`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const get_all_sellers = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/sellers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSellers(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    get_all_courses();
    get_all_sellers();
  }, []);

  const search_register_info = async () => {
    let searchText = document.getElementById("query-term-to-search").value;

    if (searchText == null || searchText == undefined || searchText == "") {
      return;
    }

    await fetch(
      `${import.meta.env.VITE_API_URL}/course/find_courses/${searchText}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userData.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCourses(data);

        if (data.length >= 0) {
          setDisableRestart(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const restart_data = () => {
    document.getElementById("query-term-to-search").value = "";
    setDisableRestart(true);
    get_all_courses();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search_register_info();
    }
  };

  const screenWidth = useWindowWidth();

  return (
    <Fragment>
      <Navbar />
      {/* <Box
        sx={{
          position: "relative",
          "&:before": {
            content: '""',
            background: "radial-gradient(#d2f1df, #d3d7fa, #bad8f4)",
            backgroundSize: "400% 400%",
            animation: "gradient 15s ease infinite",
            position: "absolute",
            height: "100%",
            width: "100%",
            opacity: "0.3",
          },
        }}
      >
        <Grid
          container
          spacing={0}
          justifyContent="center"
          sx={{ height: "93vh" }}
        >
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              elevation={9}
              sx={{ p: "0 32px", zIndex: 1, width: "100%", maxWidth: "500px" }}
            >
              <StandardForm
                title={
                  <StyledTypography
                    variant="h3"
                    sx={{
                      fontFamily: "Roboto Slab, serif",
                      mb: 1,
                    }}
                  >
                    Nuevo curso
                  </StyledTypography>
                }
                subtext={
                  <Typography
                    variant="subtitle1"
                    textAlign="center"
                    color="textSecondary"
                    mb={1}
                  >
                    Cursos de Industrial Seminars
                  </Typography>
                }
                sellerUsers={sellers}
                formData={FORM_DATA}
                courses={courses}
                setCourses={setCourses}
                userData={userData}
                userEmail={payload.email}
              />
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Carousel
              elements={courses}
              setElements={setCourses}
              userData={userData}
              userEmail={payload.email}
              formData={FORM_DATA}
              sellerUsers={sellers}
            />
          </Grid>
        </Grid>
      </Box> */}

      <Container maxWidth="xl" className="container-more-than-full-width">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6} className="search-input-container">
              <StyledPaper>
                <TextField
                  onKeyDown={handleKeyDown}
                  id="query-term-to-search"
                  className="input-query-search"
                  label="Datos de algún curso"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  size="small"
                  placeholder="Escriba su búsqueda"
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={6} className="buttons-container">
              <StyledPaper>
                <Stack direction="row" spacing={2}>
                  {screenWidth >= 1920 ? (
                    <Button
                      className="complementary-buttons-search-desktop"
                      onClick={search_register_info}
                      variant="contained"
                      color="secondary"
                    >
                      Buscar
                    </Button>
                  ) : (
                    <Tooltip title="Buscar">
                      <Button
                        className="complementary-buttons-search-mobile"
                        aria-label="search"
                        onClick={search_register_info}
                        variant="contained"
                        color="secondary"
                      >
                        <SearchOutlinedIcon />
                      </Button>
                    </Tooltip>
                  )}

                  {screenWidth >= 1920 ? (
                    <Button
                      className="complementary-buttons-search-desktop"
                      onClick={restart_data}
                      disabled={disableRestart}
                      variant="contained"
                      color="info"
                    >
                      Restablecer
                    </Button>
                  ) : (
                    <Button
                      className="complementary-buttons-search-mobile"
                      onClick={restart_data}
                      disabled={disableRestart}
                      variant="contained"
                      color="info"
                    >
                      <Tooltip title="Restablecer">
                        <RestartAltIcon />
                      </Tooltip>
                    </Button>
                  )}
                  <AddNewCourse
                    courses={courses}
                    setCourses={setCourses}
                    sellerUsers={sellers}
                  />
                </Stack>
              </StyledPaper>
            </Grid>

            <Grid item xs={12} className="main-table-container">
              <StyledPaper className="styled-paper-table-container">
                <EnhancedTable
                  accessToken={userData.access_token}
                  registers={courses}
                  setRegisters={setCourses}
                  sellers={sellers}
                  setSellers={setSellers}
                  userEmail={payload.email}
                />
              </StyledPaper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export { AddCourse };
