import { Fragment, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const phoneNumberAutoFormat = (phoneNumber) => {
  const number = phoneNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11)
    return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

const TelephoneInput = ({
  isDisabled = false,
  isCellPhone = false,
  initialState = false,
  setValueWrapper = false,
  fieldLabel,
  fieldName,
  hasTelephoneExtension = false,
  savedTelephone = false,
  setElementValues = false,
  elementIndex = false,
  savedTelephoneExtension = false,
  required = false,
  size = "medium",
  fontSizeCheckbox = "16px",
  checkboxName = "has_telephone_extension",
  extensionName = "telephone_extension",
}) => {
  const [phone, setPhone] = useState(savedTelephone ? savedTelephone : "");
  const [extension, setExtension] = useState(
    savedTelephoneExtension ? savedTelephoneExtension : ""
  );
  const [checked, setChecked] = useState(hasTelephoneExtension);

  const singlePhone = (event) => {
    const value = phoneNumberAutoFormat(event.target.value);
    setPhone(value);
    if (initialState) {
      setValueWrapper({
        ...initialState,
        [fieldName]: value,
      });
    }
  };

  const multiplePhone = (event) => {
    const { name, value } = event.target;
    let phone = phoneNumberAutoFormat(value);
    setPhone(phone);

    setElementValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[elementIndex] = {
        ...updatedValues[elementIndex],
        [name]: value,
      };
      return updatedValues;
    });
  };

  const handlePhoneChange = (e) => {
    if (setElementValues) {
      multiplePhone(e);
    } else {
      singlePhone(e);
    }
  };

  const singlePhoneExtension = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setExtension(value);
  };

  const multiplePhoneExtension = (event) => {
    const { name, value } = event.target;
    let phoneExtension = value.replace(/\D/g, "");
    setExtension(phoneExtension);

    setElementValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[elementIndex] = {
        ...updatedValues[elementIndex],
        [name]: phoneExtension,
      };
      return updatedValues;
    });
  };

  const handleExtensionChange = (e) => {
    if (setElementValues) {
      multiplePhoneExtension(e);
    } else {
      singlePhoneExtension(e);
    }
  };

  const singleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const multipleCheckbox = (event) => {
    const { name, checked } = event.target;
    setChecked(checked);

    setElementValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[elementIndex] = {
        ...updatedValues[elementIndex],
        [name]: checked,
      };
      return updatedValues;
    });
  };

  const handleCheckboxChange = (event) => {
    if (setElementValues) {
      multipleCheckbox(event);
    } else {
      singleCheckbox(event);
    }
  };

  const ExtensionCheckbox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            className="extension-checkbox"
            checked={checked}
            disabled={isDisabled}
            onChange={handleCheckboxChange}
            color="secondary"
            size={size}
            name={checkboxName}
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        }
        label="¿Agregar extensión de teléfono?"
        sx={{
          "& .MuiFormControlLabel-label": {
            fontSize: fontSizeCheckbox,
          },
        }}
      />
    );
  };

  useEffect(() => {
    setChecked(hasTelephoneExtension);

    if (setElementValues && fieldName != "contact_cell_phone") {
      setElementValues((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[elementIndex] = {
          ...updatedValues[elementIndex],
          [`has_${fieldName}_extension`]: checked,
        };
        return updatedValues;
      });
    }
  }, [hasTelephoneExtension]);

  useEffect(() => {
    savedTelephone ? setPhone(savedTelephone) : setPhone("");
    savedTelephoneExtension
      ? setExtension(savedTelephoneExtension)
      : setExtension("");
  }, [savedTelephone, savedTelephoneExtension]);

  return (
    <Fragment>
      <Box>
        <TextField
          type="tel"
          disabled={isDisabled}
          size={size ? size : "normal"}
          required={required}
          value={phone}
          onChange={handlePhoneChange}
          fullWidth
          margin="dense"
          label={fieldLabel}
          name={fieldName}
          variant="outlined"
          color="secondary"
          inputProps={{ maxLength: 12 }}
        />
      </Box>
      {!isCellPhone && <ExtensionCheckbox />}
      {checked && (
        <Box>
          <TextField
            id="outlined-error-helper-text"
            type="text"
            disabled={isDisabled}
            size={size ? size : "normal"}
            value={extension}
            onChange={handleExtensionChange}
            maxLength={5}
            placeholder="Ej: 123"
            label="Extensión telefónica"
            name={extensionName}
            variant="outlined"
            color="secondary"
            inputProps={{ maxLength: 5, pattern: "[0-9]*" }}
            error={checked && extension.length == 0}
            required={checked}
            helperText="Ingresar dato"
          />
        </Box>
      )}
    </Fragment>
  );
};

export { TelephoneInput };
