import { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

const DeleteDialog = ({
  mainButton,
  formTitle,
  formContent,
  confirmButton,
  courseId,
  deleteFunction,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletion = () => {
    deleteFunction(courseId);
    handleClose();
  };

  return (
    <Fragment>
      <IconButton color="error" aria-label="delete" onClick={handleClickOpen}>
        {mainButton}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "#f45757", fontWeight: "700" }}
        >
          {formTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {formContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleDeletion}
            autoFocus
          >
            {confirmButton}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export { DeleteDialog };
