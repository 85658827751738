import { Fragment, useState, useEffect, useContext } from "react";

import { Navbar } from "../../Components/Navbar";
import { EnhancedTable } from "./EnhancedTable";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { useWindowWidth } from "../../Tools/Shared";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Swal from "sweetalert2";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#9c27b0",
  fontWeight: "bold",
  marginBottom: theme.spacing(4),
  textAlign: "center",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
}));

const StyledPaper = styled(Paper)((props) => ({
  boxShadow: "unset",
}));

const FORM_DATA = [
  {
    id: 1,
    name: "role",
    label: "Rol",
    type: "select",
    placeholder: "Selecciona un rol",
    multiline: false,
    required: true,
    endIcon: false,
  },
  {
    id: 2,
    name: "name",
    label: "Nombre",
    type: "text",
    placeholder: "Nombre completo",
    multiline: false,
    required: true,
    endIcon: false,
  },
  {
    id: 3,
    name: "email",
    label: "Email",
    type: "email",
    placeholder: "nombre.apellido@dominio.com",
    multiline: false,
    required: true,
    endIcon: false,
  },
  {
    id: 4,
    name: "password",
    label: "Contraseña",
    type: "password",
    placeholder: "Escriba una contraseña",
    multiline: false,
    required: true,
    endIcon: true,
  },
  {
    id: 5,
    name: "password_confirmation",
    label: "Confirmar contraseña",
    type: "password",
    placeholder: "Escribe la misma contraseña",
    multiline: false,
    required: true,
    endIcon: true,
  },
];

const AddNewUser = ({ users, setUsers, role, setRole }) => {
  const { userData } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const payload = decodeJWT(userData.access_token);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const addUser = async (event) => {
    event.preventDefault();

    const user_email = payload.email;
    let name = document.getElementById("name");
    let email = document.getElementById("email");
    let password = document.getElementById("password");
    let password_conf = document.getElementById("password_confirmation");

    if (password.value !== password_conf.value) {
      Swal.fire({
        title: "Error",
        text: "Las contraseñas no coinciden",
        icon: "error",
        customClass: {
          container: "swal-password-error",
        },
      });
      return;
    }

    await fetch(`${import.meta.env.VITE_API_URL}/user/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
      body: JSON.stringify({
        user_email,
        name: name.value,
        email: email.value,
        password: password.value,
        role,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        name.value = "";
        email.value = "";
        password.value = "";
        password_conf.value = "";
        setRole("");
        setUsers([...users, data.created_user]);
        Swal.fire({
          title: "Usuario creado",
          icon: "success",
        });
        handleClose();
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const screenWidth = useWindowWidth();

  return (
    <Fragment>
      {screenWidth >= 1600 ? (
        <Button
          onClick={handleClickOpen}
          variant="contained"
          className="add-new-register-button-desktop"
          sx={{
            borderRadius: 5,
          }}
        >
          Nuevo Usuario
        </Button>
      ) : (
        <Tooltip title="Nuevo usuario">
          <Button
            onClick={handleClickOpen}
            variant="contained"
            className="add-new-register-button-mobile"
          >
            <PersonAddAltOutlinedIcon />
          </Button>
        </Tooltip>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            addUser(event);
          },
        }}
      >
        <DialogTitle>Agregar Nuevo Usuario</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingrese la información del nuevo usuario
          </DialogContentText>
          <Stack mb={1}>
            {FORM_DATA.map((item) => (
              <Fragment key={item.id}>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontWeight={600}
                  component="label"
                  htmlFor={item.name}
                  mb="5px"
                  mt="15px"
                  sx={{
                    fontFamily: "Roboto Slab, serif",
                  }}
                >
                  {item.label} {item.required ? "*" : ""}
                </Typography>

                {item.type === "select" ? (
                  <Fragment>
                    <Select
                      labelId="simple-select-label"
                      color="secondary"
                      id={item.name}
                      value={role}
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value={1527}>Vendedor</MenuItem>
                      <MenuItem value={1685}>Promotor</MenuItem>
                      <MenuItem value={1894}>Administrador</MenuItem>
                    </Select>
                  </Fragment>
                ) : (
                  <TextField
                    id={item.name}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    type={
                      item.type === "password"
                        ? showPassword
                          ? "text"
                          : "password"
                        : "text"
                    }
                    placeholder={item.placeholder}
                    required={item.required}
                    InputProps={{
                      endAdornment: item.endIcon ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="secondary" variant="contained" type="submit">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const idSuperUser = "6712040bd5e9bcac6b36e9eb";

const AddUser = () => {
  const { userData } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");
  const [disableRestart, setDisableRestart] = useState(true);

  const getAllUsers = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.filter((item) => item.id !== idSuperUser));
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const search_register_info = async () => {
    let searchText = document.getElementById("query-term-to-search").value;

    if (searchText == null || searchText == undefined || searchText == "") {
      return;
    }

    await fetch(
      `${import.meta.env.VITE_API_URL}/user/find_users/${searchText}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userData.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.filter((item) => item.id !== idSuperUser));

        if (data.length >= 0) {
          setDisableRestart(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const restart_data = () => {
    document.getElementById("query-term-to-search").value = "";
    setDisableRestart(true);
    getAllUsers();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search_register_info();
    }
  };

  const payload = decodeJWT(userData.access_token);

  const screenWidth = useWindowWidth();

  return (
    <Fragment>
      <Navbar />

      <Container maxWidth="xl" className="container-more-than-full-width">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6} className="search-input-container">
              <StyledPaper>
                <TextField
                  onKeyDown={handleKeyDown}
                  id="query-term-to-search"
                  className="input-query-search"
                  label="Datos de usuario"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  size="small"
                  placeholder="Escriba su búsqueda"
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={6} className="buttons-container">
              <StyledPaper>
                <Stack direction="row" spacing={2}>
                  {screenWidth >= 1920 ? (
                    <Button
                      className="complementary-buttons-search-desktop"
                      onClick={search_register_info}
                      variant="contained"
                      color="secondary"
                    >
                      Buscar
                    </Button>
                  ) : (
                    <Tooltip title="Buscar">
                      <Button
                        className="complementary-buttons-search-mobile"
                        aria-label="search"
                        onClick={search_register_info}
                        variant="contained"
                        color="secondary"
                      >
                        <SearchOutlinedIcon />
                      </Button>
                    </Tooltip>
                  )}

                  {screenWidth >= 1920 ? (
                    <Button
                      className="complementary-buttons-search-desktop"
                      onClick={restart_data}
                      disabled={disableRestart}
                      variant="contained"
                      color="info"
                    >
                      Restablecer
                    </Button>
                  ) : (
                    <Button
                      className="complementary-buttons-search-mobile"
                      onClick={restart_data}
                      disabled={disableRestart}
                      variant="contained"
                      color="info"
                    >
                      <Tooltip title="Restablecer">
                        <RestartAltIcon />
                      </Tooltip>
                    </Button>
                  )}
                  <AddNewUser
                    users={users}
                    setUsers={setUsers}
                    role={role}
                    setRole={setRole}
                  />
                </Stack>
              </StyledPaper>
            </Grid>

            <Grid item xs={12} className="main-table-container">
              <StyledPaper className="styled-paper-table-container">
                <EnhancedTable
                  accessToken={userData.access_token}
                  registers={users}
                  setRegisters={setUsers}
                  userEmail={payload.email}
                />
              </StyledPaper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export { AddUser };
